<template>
  <v-container>
    <v-row>
      <v-col lg="12" sm="12" style="text-align: center">
        <v-tabs
            v-model="tab"
            background-color="transparent"
            centered
            slider-color="transparent"
            icons-and-text
            active-class="tab_active"
        >
          <v-tab href="#configurations" style="min-width: 100px">
            Configuration
            <v-icon color="#066a8c">mdi-package-variant-closed</v-icon>
          </v-tab>
          <v-tab href="#tags" style="min-width: 100px">
            Tags
            <v-icon color="#066a8c">mdi-palette</v-icon>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab">
      <v-tab-item value="configurations">
        <v-form ref="conf_form">
          <div class="titles mt-6">Documents</div>
          <v-divider class="mb-3"></v-divider>
          <v-card
              outlined
              class="mb-8"
              :style="cardStyle"
              v-for="(document,
            k) in configurations.field_document_configurations"
              :key="'d_' + k"
          >
            <v-btn
                v-if="k == 0"
                small
                @click="gotoMembership"
                absolute
                top
                right
                style="top: -50px; margin-right: -1%"
            >
              <v-icon small>mdi-backburger</v-icon>Back
            </v-btn>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                      label="Name"
                      required
                      outlined
                      background-color="#fff"
                      v-model="document.name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="4" md="4">
                  <v-file-input
                      :label="document.document_id != null ? '' : 'Document'"
                      v-model="document.file"
                      prepend-inner-icon="mdi-paperclip"
                      prepend-icon
                      outlined
                      background-color="#fff"
                  >
                    <template v-slot:label>
                      <span v-if="!document.document_id"> Select file </span>
                      <span
                          v-if="document.document_id && !document.file"
                          class="font-weight-bold"
                      >
                        <span
                            style="width: 70%; display: inline-block"
                            class="text-truncate"
                        >{{ document.uploaded_original_file_name }}</span
                        >
                        <span
                            style="width: 20%; display: inline-block"
                            class="text-truncate"
                        >.{{
                            document.uploaded_original_file_name.split(".")[
                            document.uploaded_original_file_name.split(".")
                                .length - 1
                                ]
                          }}</span
                        >
                      </span>
                    </template>
                  </v-file-input>
                </v-col>

                <v-col cols="12" sm="2" md="2">
                  <v-checkbox
                      v-model="document.is_visible"
                      label="View"
                      color="success"
                      value="document.is_visible"
                      v-bind:false-value="0"
                      v-bind:true-value="1"
                      hide-details
                      @change="
                      !document.is_visible ? (document.is_required = 0) : ''
                    "
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-checkbox
                      v-model="document.is_required"
                      label="Mandatory"
                      color="success"
                      value="document.is_required"
                      hide-details
                      v-bind:false-value="0"
                      v-bind:true-value="1"
                      @change="
                      document.is_required ? (document.is_visible = 1) : ''
                    "
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="1" md="1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          style="position: relative; top: calc(50% - 33px)"
                          v-if="
                          checkDeletePermission(
                            $modules.facility.management.slug
                          )
                        "
                          v-bind="attrs"
                          v-on="on"
                          color="red"
                          class="mt-2"
                          @click="deleteDocuments(k)"
                          fab
                          x-small
                          dark
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    Delete
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <div class="add_btn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="teal-color"
                    fab
                    x-small
                    dark
                    @click="addDocuments"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              Add Document
            </v-tooltip>
          </div>

          <!-- <div class="titles mt-6">Membership card</div>
      <v-divider class="mb-3"></v-divider>
      <v-card outlined class="mb-8" :style="cardStyle">
        <v-card-text>
          <v-row>
            <v-col sm="6" md="6">
              <v-row no-gutters>
                <v-col md="12">
                  <v-file-input
                    v-model="configurations.membership_configurations.file"
                    class="text_field1"
                    prepend-icon=""
                    :placeholder="`${
                      configurations.membership_configurations.image_path
                        ? 'Change'
                        : 'Select'
                    }`"
                    :label="`${
                      configurations.membership_configurations.image_path
                        ? 'Change image'
                        : 'Upload Image'
                    } `"
                    background-color="#fff"
                    outlined
                    show-size
                    accept=".jpeg,.png,.jpg"
                    @change="onFileChange"
                  >
                    <template v-slot:prepend-inner>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            color="cyan"
                            v-if="
                              configurations.membership_configurations
                                .image_path
                            "
                            @click="
                              openFile(
                                configurations.membership_configurations
                                  .image_path
                              )
                            "
                            v-on="on"
                          >
                            mdi-download-box
                          </v-icon>
                          <v-icon v-else v-on="on">mdi-image</v-icon>
                        </template>
                        <span
                          v-if="
                            configurations.membership_configurations
                              .image_path
                          "
                        >
                          Download image</span
                        >
                        <span v-else>Upload Image</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index == 0"
                        color="cyan accent-4"
                        dark
                        label
                        small
                      >
                        <span style="width: 120px" class="text-truncate">{{
                          text
                        }}</span>
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="6">
              <v-row no-gutters class="pt-2">
                <v-btn
                  dark
                  large
                  @click="showMemberShipCard = true"
                  color="#066a8c"
                  >View</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card> -->

          <div class="titles mt-6">Preferences</div>
          <v-card outlined class="mb-8" :style="cardStyle">
            <v-card-text>
              <v-row>
                <v-col lg="3" md="4">
                  <v-checkbox
                      v-model="
                      configurations.membership_configurations
                        .is_enable_email_reminder
                    "
                      class="mt-2"
                      false-value="0"
                      true-value="1"
                      label="Enable reminder for membership renew"
                  ></v-checkbox>
                </v-col>
                <v-col lg="3" md="4">
                  <v-checkbox
                      v-model="
                      configurations.membership_configurations
                        .is_enable_approval_process
                    "
                      class="mt-2"
                      false-value="0"
                      true-value="1"
                      label="Member approval from QPortal"
                  ></v-checkbox>
                </v-col>
                <v-col lg="3" md="4" v-if="checkReadPermission($modules.memberships.autoDebit.slug)">
                  <v-checkbox
                      v-model="
                      configurations.membership_configurations
                        .enable_auto_debit
                    "
                      class="mt-2"
                      false-value="0"
                      true-value="1"
                      label="Auto Debit Installment"
                  ></v-checkbox>
                </v-col>
                <v-col lg="2" md="2">
                  <v-checkbox
                      v-model="
                      configurations.membership_configurations.tnc_enable
                    "
                      class="mt-2"
                      false-value="0"
                      true-value="1"
                      label="Enable Website T&C's"
                  ></v-checkbox>
                </v-col>
                <v-col lg="2" md="2">
                  <v-text-field
                      label="Expiry Alert Days"
                      required
                      v-model="
                      configurations.membership_configurations.expiry_alert_days
                    "
                      outlined
                      background-color="#fff"
                      :rules="[
                      (v) => !!v || 'Expiry Alert days are required',
                      (v) => !isNaN(v) || 'Expiry Alert days must be Number',
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row
                  v-if="
                  configurations.membership_configurations
                    .is_enable_email_reminder == '1'
                "
              >
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                      v-model="
                      configurations.membership_configurations.email_title
                    "
                      outlined
                      background-color="#fff"
                      label="Subject*"
                      required
                      :rules="[
                      (v) => {
                        if (
                          !v &&
                          configurations.membership_configurations
                            .is_enable_email_reminder == '1'
                        ) {
                          return 'Subject is required';
                        }
                        return true;
                      },
                    ]"
                  ></v-text-field>
                </v-col>
                <div class="ml-4" style="margin-top: -10px">
                  <span class="overline">Placeholders</span>
                  <v-tooltip top max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="grey lighten-1">mdi-information</v-icon>
                      </v-btn>
                    </template>
                    <span class="text-center">
                      Placeholder is used to add dynamic data in you message.
                      Placerholder will be replaced with actual data
                      representing each of them. Please click to copy and paste
                      it on your message
                    </span>
                  </v-tooltip>
                  <template>
                    <v-chip
                        class="mr-2 pa-4"
                        @click="copyPlaceholder('MEMBER_NAME')"
                    >
                      Member Name
                      <v-icon right small>mdi-content-copy</v-icon>
                    </v-chip>
                    <v-chip
                        class="mr-2 pa-4"
                        @click="copyPlaceholder('MEMBERSHIP_NAME')"
                    >
                      Membership
                      <v-icon right small>mdi-content-copy</v-icon>
                    </v-chip>
                    <v-chip
                        class="mr-2 pa-4"
                        @click="copyPlaceholder('EXPIRY_DATE')"
                    >
                      Expiry Date
                      <v-icon right small>mdi-content-copy</v-icon>
                    </v-chip>
                  </template>
                </div>
                <v-col cols="12" sm="12" md="12">
                  <text-editor
                      @complete="setEmailContent"
                      :message="
                      configurations.membership_configurations.email_message
                    "
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row
              class="mt-10 mb-10"
              style="border: 1px solid black;"
              v-if="configurations.membership_configurations.tnc_enable == 1"
          >
            <v-card style="width: 100%; min-width: 300px">
              <h2 class="pa-4">Terms and Conditions</h2>
              <div
                  class="px-4 pb-4 d-flex flex-row flex-wrap"
                  style="width: 100%"
              >
                <text-editor
                    @complete="setTncContent"
                    :message="configurations.membership_configurations.tnc"
                    style="width: 100%"
                />
              </div>
            </v-card>
          </v-row>
          <div
              class="titles mt-2"
              v-if="checkWritePermission($modules.memberships.freeze.slug)"
          >
            Membership Freeze Fee Configurations
          </div>
          <v-divider></v-divider>
          <v-card
              class="mb-8 pa-2"
              :style="cardStyle"
              v-if="checkWritePermission($modules.memberships.freeze.slug)"
          >
            <v-row>
              <v-col cols="3" sm="6" md="3">
                <v-text-field
                    outlined
                    background-color="#fff"
                    v-model="product.name"
                    hide-details="auto"
                    label="Memebership Freeze Name *"
                    placeholder="Product Name"
                    required
                    :rules="[(v) => !!v || 'Product name is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="6" md="3">
                <v-select
                    hide-details="auto"
                    label="Tax"
                    v-model="product.tax_type_id"
                    item-value="value"
                    item-text="text"
                    :items="taxTypes"
                    @change="taxChange"
                    :menu-props="{ bottom: true, offsetY: true }"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[(v) => !!v || 'Tax type is required']"
                ></v-select>
              </v-col>
              <v-col cols="3" sm="6" md="3">
                <v-text-field
                    hide-details="auto"
                    label="Price / Month (Pre Tax)*"
                    outlined
                    :prefix="currencyCode"
                    background-color="#fff"
                    v-model="product.pre_tax_price"
                    @change="calculateTaxVariation($event, 'pre')"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="6" md="3">
                <v-text-field
                    hide-details="auto"
                    label="Price / Month (Post Tax)*"
                    outlined
                    :prefix="currencyCode"
                    background-color="#fff"
                    v-model="product.total_price"
                    @change="calculateTaxVariation($event, 'post')"
                    required
                    :rules="[
                    (v) => {
                      if (v >= 0) {
                        return true;
                      }
                      return 'Price is required';
                    },
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
          <div class="titles mt-2">Booking Configurations</div>
          <v-divider></v-divider>
          <v-card class="mb-8" :style="cardStyle">
            <v-row>
              <v-col>
                <table class="settings_table">
                  <thead>
                  <tr>
                    <th>Field Name</th>
                    <th>View</th>
                    <th>Mandatory</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(config,
                      index) in configurations.field_configurations"
                      :key="index"
                  >
                    <td>{{ config.name }}</td>
                    <td>
                      <v-btn
                          icon
                          :color="config.is_visible == 1 ? 'success' : 'error'"
                          @click="checkAsVisible(index)"
                      >
                        <v-icon
                            :style="
                              viewRestrictedFields.includes(config.slug) &&
                                'cursor:not-allowed;'
                            "
                        >
                          {{
                            config.is_visible == 1 ? "mdi-check" : "mdi-close"
                          }}
                        </v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                          icon
                          :color="config.is_required == 1 ? 'success' : 'error'"
                          @click="checkAsMandatory(index)"
                      >
                        <v-icon
                            :style="
                              mandatoryRestrictedFields.includes(config.slug) &&
                                'cursor:not-allowed;'
                            "
                        >
                          {{
                            config.is_required == 1
                                ? "mdi-check"
                                : "mdi-close"
                          }}
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="ma-2 white--text teal-color"
                text
                height="45"
                @click="saveCOnfiguration()"
            >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-tab-item>
      <v-tab-item value="tags">
        <v-form ref="cform">
          <div class="titles">Tags</div>
          <v-row>
            <v-col
                md="4"
                sm="6"
                lg="3"
                cols="12"
                v-for="(code, cIndex) in colorCodes"
                :key="`index${cIndex}`"
                style="position: relative"
            >
              <v-card class="shadow-2">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12" sm="12"  class="pb-0">
                      <label for="">
                        Tag name
                      </label>
                      <v-text-field
                          outlined
                          background-color="#fff"
                          light
                          v-model="code.name"
                          dense
                          hide-details="auto"
                          class="q-text-field shadow-0"
                          required
                          :rules="[(v) => !!v || 'Tag name is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col  md="12" sm="12" cols="12">
                      <label for="">
                        Color Code
                      </label>
                      <v-text-field
                          outlined
                          v-model="code.color_code"
                          background-color="#fff"
                          light
                          dense
                          hide-details="auto"
                          class="q-text-field shadow-0 color-picker"
                          required
                          :rules="[(v) => !!v || 'Color code is required']"
                      >
                        <template v-slot:append>
                          <v-menu
                              top
                              nudge-bottom="105"
                              nudge-left="16"
                              :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on }">
                              <div :style="swatchStyle(cIndex)" v-on="on"/>
                            </template>
                            <v-card>
                              <v-card-text class="pa-0">
                                <v-color-picker
                                    v-model="code.color_code"
                                    flat
                                    :swatches="swatches"
                                    show-swatches
                                />
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-tooltip bottom v-if="colorCodes.length > 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="red"
                      dark
                      elevation="0"
                      fab
                      v-bind="attrs"
                      x-small
                      absolute
                      top
                      style="top:-5px;"
                      right
                      @click="deleteCode(cIndex)"
                      v-on="on"
                  >
                    <DeleteIcon/>
                  </v-btn>
                </template>
                Delete
              </v-tooltip>
            </v-col>
          </v-row>
<!--          new till here -->
<!--          <v-card-->
<!--              :style="cardStyle"-->
<!--              v-for="(code, cIndex) in colorCodes"-->
<!--              :key="`cc_${cIndex}`"-->
<!--              class="mb-4"-->
<!--              flat-->
<!--          >-->
<!--            <v-container>-->
<!--              <v-row>-->
<!--                <v-col :md="colorCodes.length > 1 ? 6 : 7">-->
<!--                  <v-text-field-->
<!--                      outlined-->
<!--                      background-color="#fff"-->
<!--                      light-->
<!--                      v-model="code.name"-->
<!--                      label="Code name*"-->
<!--                      :rules="[(v) => !!v || 'Code name is required']"-->
<!--                  ></v-text-field>-->
<!--                </v-col>-->
<!--                <v-col>-->
<!--                  <v-text-field-->
<!--                      outlined-->
<!--                      v-model="code.color_code"-->
<!--                      background-color="#fff"-->
<!--                      light-->
<!--                      :rules="[(v) => !!v || 'Color code is required']"-->
<!--                  >-->
<!--                    <template v-slot:append>-->
<!--                      <v-menu-->
<!--                          top-->
<!--                          nudge-bottom="105"-->
<!--                          nudge-left="16"-->
<!--                          :close-on-content-click="false"-->
<!--                      >-->
<!--                        <template v-slot:activator="{ on }">-->
<!--                          <div :style="swatchStyle(cIndex)" v-on="on" />-->
<!--                        </template>-->
<!--                        <v-card>-->
<!--                          <v-card-text class="pa-0">-->
<!--                            <v-color-picker-->
<!--                                v-model="code.color_code"-->
<!--                                flat-->
<!--                                :swatches="swatches"-->
<!--                                show-swatches-->
<!--                            />-->
<!--                          </v-card-text>-->
<!--                        </v-card>-->
<!--                      </v-menu>-->
<!--                    </template>-->
<!--                  </v-text-field>-->
<!--                </v-col>-->
<!--                <v-col md="1" v-if="colorCodes.length > 1" class="mt-2">-->
<!--                  <v-tooltip bottom>-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                      <v-btn-->
<!--                          v-bind="attrs"-->
<!--                          v-on="on"-->
<!--                          class="del_btn"-->
<!--                          color="red"-->
<!--                          @click="deleteCode(cIndex)"-->
<!--                          x-small-->
<!--                          dark-->
<!--                          fab-->
<!--                      >-->
<!--                        <v-icon small>mdi-delete</v-icon>-->
<!--                      </v-btn>-->
<!--                    </template>-->
<!--                    Delete-->
<!--                  </v-tooltip>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </v-container>-->
<!--          </v-card>-->
          <div class="add_btn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="blue-color"
                    fab
                    x-small
                    dark
                    @click="addColorCode()"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              Add new code
            </v-tooltip>
          </div>
          <div style="float: right">
            <v-btn
                class="white--text blue-color"
                @click="saveColorCodes($event)"
            >Update</v-btn
            >
          </div>
        </v-form>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="showMemberShipCard" scrollable width="800">
      <FlipCard>
        <template slot="front">
          <v-card
              class="pa-5 member-card"
              :style="{ backgroundImage: 'url(' + membershipCardImage + ')' }"
          >
            <v-container fill-height fluid>
              <v-row>
                <v-col md="6" style="text-align: center" class="pa-13 pb-12">
                  <v-avatar
                      color="primary"
                      size="170"
                      style="text-align: center"
                  >
                    <v-img class="elevation-6" alt=""></v-img
                    ></v-avatar>
                </v-col>
                <v-col md="1"></v-col>

                <v-col md="5" class="pt-0 pr-0">
                  <v-img
                      width="100"
                      alt=""
                      style="margin-left: auto"
                      :src="s3BucketURL + this.$store.getters.venueInfo.logo"
                  ></v-img>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="2">
                  <span> NAME :</span>
                </v-col>
                <v-col md="5">
                  <span> </span>
                </v-col>
                <v-col md="2" class="pl-0 pr-0">
                  <span> MEMBERSHIP # :</span>
                </v-col>
                <v-col md="3">
                  <span> </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="2">
                  <span> VALIDITY :</span>
                </v-col>
                <v-col md="5">
                  <span> TO </span>
                </v-col>
                <v-col md="2" class="pl-0">
                  <span> TYPE :</span>
                </v-col>
                <v-col md="3">
                  <span> Package Name </span>
                </v-col>
              </v-row>
            </v-container>
            <!-- </v-card> -->
          </v-card>
        </template>
        <template slot="back">
          <v-card
              class="pa-5 member-card"
              style="height: 100%"
              :style="{ backgroundImage: 'url(' + membershipCardImage + ')' }"
          >
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col style="text-align: center" class="pa-15"> </v-col>
              </v-row>
            </v-container>
            <!-- </v-card> -->
          </v-card>
        </template>
      </FlipCard>
    </v-dialog>
    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import constants from "@/utils/constants";
import FlipCard from "./MembershipTabs/MembershipCard.vue";
import TextEditor from "@/components/Marketing/TextEditor";
import DeleteIcon from "@/assets/images/retail/delete-bg-icon.svg";
export default {
  components: {DeleteIcon, FlipCard, TextEditor },
  data() {
    return {
      configurations: {
        field_configurations: [],
        field_document_configurations: [],
        venue_service_configuration: {},
        deleted_field_documents: [],
        membership_configurations: {
          tnc_enable: 0,
          tnc: "",
          is_enable_approval_process: "1",
          enable_auto_debit: "0",
          expiry_alert_days: 10,
          is_enable_email_reminder: "0",
          email_title: "",
          email_message: "",
        },
      },
      email_message: "",
      tnc: "",
      showMemberShipCard: false,
      venueServiceId: null,
      viewRestrictedFields: constants.VIEWRESTRICTEDFIELDS,
      mandatoryRestrictedFields: constants.MANTATORYRESTRICTEDFIELDS,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      tab: "packages",
      colorCodes: [
        {
          name: null,
          color_code: "#00AAAAFF",
        },
      ],
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
      deleted_codes: [],
      membershipCardImage: require("@/assets/images/default_images/membership_card.png"),
      product: {
        id: "",
        name: "Membership Freeze Fee",
        tax_type_id: 2,
        product_type_id: 2,
        pre_tax_price: 0.0,
        tax_amount: 0.0,
        price: 0.0,
        total_price: 0.0,
      },
    };
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then((data) => {
        if (data.length) {
          this.venueServiceId = data[0].venue_service_id;
          this.loadConfigurations();
        }
      });
    } else {
      this.venueServiceId = this.venueServices[0].venue_service_id;
      this.loadConfigurations();
    }
    if (this.$store.getters.getDocumentTypes.status == false) {
      this.$store.dispatch("loadDocumentTypes");
    }
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }
    this.getConfiguration();
  },
  computed: {
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
    documentTypes() {
      return this.$store.getters.getDocumentTypes.data;
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
  },
  methods: {
    loadConfigurations() {
      this.getColorCodes();
      // this.loadTrainingServices();
    },
    addColorCode() {
      this.colorCodes.push({
        name: null,
        color_code: "#00AAAAFF",
      });
      if (this.$refs.cform) {
        this.$refs.cform.resetValidation();
      }
    },
    swatchStyle(cIndex) {
      const { color_code, menu } = this.colorCodes[cIndex];
      return {
        backgroundColor: color_code,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    getColorCodes() {
      this.$http
          .get(`venues/memberships/tags`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              if (data.length) {
                this.colorCodes = data;
              } else {
                this.colorCodes = [
                  {
                    name: null,
                    color_code: "#00AAAAFF",
                  },
                ];
              }
              this.deleted_codes = [];
              this.$forceUpdate();
              if (this.$refs.cform) {
                this.$refs.cform.resetValidation();
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    saveColorCodes() {
      if (!this.$refs.cform.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let data = {
        color_codes: this.colorCodes,
      };
      if (this.deleted_codes.length) {
        data.deleted_codes = this.deleted_codes;
      }
      this.showLoader();
      this.$http
          .post(`venues/memberships/tags`, data)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.showSuccess("Color codes saved");
              this.getColorCodes();
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    deleteCode(cIndex) {
      let pkg = this.colorCodes[cIndex];
      if (pkg.name == null && pkg.color_code == null && pkg.id == null) {
        this.colorCodes(cIndex, 1);
        this.$forceUpdate();
      } else {
        this.confirmModel = {
          id: cIndex,
          title: "Do you want to delete this color code?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action?",
          type: "color_code",
        };
      }
    },
    checkAsMandatory(index) {
      let field = this.configurations.field_configurations[index];
      if (!this.mandatoryRestrictedFields.includes(field.slug)) {
        this.configurations.field_configurations[index].is_required =
            field.is_required == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.configurations.field_configurations[index].is_visible = 1;
        }
      }
    },
    checkAsVisible(index) {
      let field = this.configurations.field_configurations[index];
      if (!this.viewRestrictedFields.includes(field.slug)) {
        this.configurations.field_configurations[index].is_visible =
            field.is_visible == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.configurations.field_configurations[index].is_required = 0;
        }
      }
    },
    addDocuments() {
      this.configurations.field_document_configurations.push({
        name: null,
        file: null,
        is_visible: 1,
        is_required: 1,
      });
    },
    deleteDocuments(index) {
      if (
          !this.configurations.field_document_configurations[index].name &&
          !this.configurations.field_document_configurations[index].id &&
          !this.configurations.field_document_configurations[index]
              .document_type_id
      ) {
        this.configurations.field_document_configurations.splice(index, 1);
        if (!this.configurations.field_document_configurations.length) {
          this.configurations.field_document_configurations = [{}];
        }
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this custom field?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete_document",
        };
      }
    },
    confirmActions(data) {
      if (data.type == "delete_document") {
        if (
            this.configurations.field_document_configurations[data.id].id != null
        ) {
          this.configurations.deleted_field_documents.push(
              this.configurations.field_document_configurations[data.id].id
          );
        }
        this.configurations.field_document_configurations.splice(data.id, 1);
        if (!this.configurations.field_document_configurations.length) {
          this.configurations.field_document_configurations = [{}];
        }
      }
      if (data.type == "color_code") {
        // console.log(data);
        // console.log(this.colorCodes);
        let index = data.id;
        if (this.colorCodes[index].id != null) {
          this.deleted_codes.push(this.colorCodes[index].id);
        }
        this.colorCodes.splice(index, 1);
        this.$forceUpdate();
      }

      this.confirmModel.id = null;
    },
    getConfiguration() {
      this.showLoader();
      this.$store
          .dispatch("loadConfigurations", "Membership")
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.configurations = JSON.parse(
                  JSON.stringify(response.data.data)
              );

              if (this.configurations.field_document_configurations.length == 0) {
                this.configurations.field_document_configurations.push({
                  name: null,
                  file: null,
                  is_required: 0,
                  is_visible: 0,
                });
              } else {
                this.configurations.field_document_configurations.forEach(
                    (element) => {
                      if (!element.document_id) {
                        element.file = null;
                      }
                    }
                );
              }

              if (this.configurations.membership_card_configuration == null) {
                this.configurations.membership_card_configuration = {
                  file: null,
                  image_path: null,
                };
              } else {
                this.membershipCardImage =
                    this.s3BucketURL +
                    this.configurations.membership_card_configuration.image_path;
              }
              if (
                  this.configurations.membership_configurations &&
                  this.configurations.membership_configurations
                      .is_enable_approval_process
              ) {
                this.configurations.membership_configurations.is_enable_approval_process = this.configurations.membership_configurations.is_enable_approval_process.toString();
              } else {
                this.configurations.membership_configurations.is_enable_approval_process =
                    "0";
              }
              if (
                  this.configurations.membership_configurations &&
                  this.configurations.membership_configurations
                      .enable_auto_debit
              ) {
                this.configurations.membership_configurations.enable_auto_debit = this.configurations.membership_configurations.enable_auto_debit.toString();
              } else {
                this.configurations.membership_configurations.enable_auto_debit =
                    "0";
              }


              if (
                  this.configurations.membership_configurations &&
                  this.configurations.membership_configurations
                      .is_enable_email_reminder
              ) {
                this.configurations.membership_configurations.is_enable_email_reminder = this.configurations.membership_configurations.is_enable_email_reminder.toString();
                this.email_message = this.configurations.membership_configurations.email_message;
              }
              if (
                  this.configurations.membership_configurations &&
                  this.configurations.membership_configurations.tnc_enable
              ) {
                this.configurations.membership_configurations.tnc_enable = this.configurations.membership_configurations.tnc_enable.toString();
              }
              this.tnc = this.configurations.membership_configurations.tnc;

              this.configurations.deleted_field_documents = [];


              if (this.configurations.membership_freeze_product) {
                this.product = {
                  id: this.configurations.membership_freeze_product.id,
                  name: this.configurations.membership_freeze_product.name,
                  pre_tax_price: this.configurations.membership_freeze_product
                      .price,
                  price: this.configurations.membership_freeze_product.price,
                  total_price: this.configurations.membership_freeze_product
                      .total_price,
                  tax_type_id: this.configurations.membership_freeze_product
                      .tax_type_id,
                  product_type_id: this.configurations.membership_freeze_product
                      .product_type_id,
                };
              }

              this.$forceUpdate();
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
            this.hideLoader();
          });
    },

    saveCOnfiguration() {
      if (!this.$refs.conf_form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let formData = new FormData();
      formData.append(`product_type`, "Membership");
      this.configurations.field_configurations.forEach((item, index) => {
        for (let key in item) {
          if (item.product_type_id == 6) {
            delete item.product_type_id;
            delete item.id;
          }
          if (item[key] != null) {
            formData.append(
                `field_configurations[${index}][${key}]`,
                item[key]
            );
          }
        }
      });
      if (this.configurations.deleted_field_documents.length) {
        this.configurations.deleted_field_documents.forEach((item, index) => {
          formData.append(`deleted_field_documents[${index}]`, item);
        });
      }

      if (this.configurations.field_document_configurations.length) {
        this.configurations.field_document_configurations.forEach(
            (item, index) => {
              if (item.name) {
                for (let key in item) {
                  formData.append(
                      `field_document_configurations[${index}][${key}]`,
                      item[key]
                  );
                }
              }
            }
        );
      }

      for (let key in this.configurations.venue_service_configuration) {
        if (
            this.configurations.venue_service_configuration[key] != null &&
            this.configurations.venue_service_configuration[key] != ""
        ) {
          formData.append(
              `venue_service_configuration[${key}]`,
              this.configurations.venue_service_configuration[key]
          );
        }
      }

      if (
          this.configurations.membership_configurations &&
          this.configurations.membership_configurations.is_enable_approval_process
      ) {
        formData.append(
            `is_enable_approval_process`,
            this.configurations.membership_configurations
                .is_enable_approval_process
        );
      }

      if (
          this.configurations.membership_configurations &&
          this.configurations.membership_configurations.enable_auto_debit
      ) {
        formData.append(
            `enable_auto_debit`,
            this.configurations.membership_configurations
                .enable_auto_debit
        );
      }

      if (
          this.configurations.membership_configurations &&
          this.configurations.membership_configurations.expiry_alert_days
      ) {
        formData.append(
            `expiry_alert_days`,
            this.configurations.membership_configurations.expiry_alert_days
        );
      }
      if (
          this.configurations.membership_configurations &&
          this.configurations.membership_configurations.is_enable_email_reminder
      ) {
        formData.append(
            `is_enable_email_reminder`,
            this.configurations.membership_configurations.is_enable_email_reminder
        );
        if (
            this.configurations.membership_configurations
                .is_enable_email_reminder == 1
        ) {
          formData.append(
              `email_title`,
              this.configurations.membership_configurations.email_title
          );

          // formData.append(`email_message`, this.email_message);
          formData.append(`email_message`, btoa(this.sanitizeHTML(this.email_message)));
        }
      }
      formData.append(
          `tnc_enable`,
          this.configurations.membership_configurations.tnc_enable
      );
      if (this.configurations.membership_configurations.tnc_enable == 1) {
        formData.append(`tnc`, btoa(this.sanitizeHTML(this.tnc)));
      }

      if (this.product) {
        formData.append(`product[id]`, this.product.id);
        formData.append(`product[name]`, this.product.name);
        formData.append(
            `product[product_type_id]`,
            this.product.product_type_id
        );
        formData.append(`product[tax_type_id]`, this.product.tax_type_id);
        formData.append(`product[price]`, this.product.pre_tax_price);
        formData.append(`product[total_price]`, this.product.total_price);
        formData.append(`product[enable_online_booking]`, 0);
        formData.append(`product[attribute_name]`, "membership_freeze_product");
      }

      this.showLoader();
      this.$http({
        method: "post",
        data: formData,
        url: "venues/configurations",
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
          .then((response) => {
            if (response.status == 200) {
              this.gotoMembership();
              this.$store
                  .dispatch("loadConfigurations", "Membership")
                  .then(() => {
                    this.$nextTick(() => {
                      this.$forceUpdate;
                    });
                  });
              this.hideLoader();
              this.showSuccess("Configuration successfully updated");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
            this.hideLoader();
            this.$store.dispatch("loadConfigurations", "Membership");
            this.$store.dispatch("loadVenueServices");
          });
    },
    gotoMembership() {
      this.$router.push({
        name: "Memberships",
      });
    },

    onFileChange(e) {
      if (e) this.membershipCardImage = window.URL.createObjectURL(e);
    },
    setEmailContent(content) {
      this.email_message = content;
    },
    setTncContent(content) {
      this.tnc = content;
    },
    copyPlaceholder(data) {
      const el = document.createElement("textarea");
      el.value = data;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
          document.getSelection().rangeCount > 0
              ? document.getSelection().getRangeAt(0)
              : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.showInfo("Placeholder for " + data + " copied to clipboard", 2000);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    taxChange() {
      if (this.product.price) {
        this.calculateTaxVariation(this.product.price, "pre");
      } else if (this.product.total_price) {
        this.calculateTaxVariation(this.product.total_price, "post");
      }
    },
    calculateTaxVariation(amount, type) {
      let taxTypeId = this.product.tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
            .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      if (priceData) {
        this.product.price = priceData.price;
        this.product.pre_tax_price = priceData.price.toFixed(2);
        this.product.total_price = priceData.total_price;
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style>
.color-picker .v-input__append-inner {
  margin-top: 6px !important;
}
</style>

<style scoped>
.settings_table {
  width: 100%;
  text-align: center !important;
}
.settings_table th {
  text-align: center !important;
}
.settings_table td,
.settings_table th {
  border: 1px solid #ddd;
  padding: 8px;
}
.settings_table tr {
  background-color: #f2f2f2;
}
.settings_table tbody td:first-child {
  font-weight: bolder;
  font-size: 13px;
}
.settings_table tr:hover {
  background-color: #ddd;
}
.settings_table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #066a8c;
  color: white;
}
.member-card {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  /* background-size: contain; */
  /* background-image: url("../../../assets/images/default_images/membership_card.png"); */
}
.member-card span {
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
}
</style>
